html {
  font-size: 16px;
  touch-action: manipulation;
}

body {
  font-size: 1rem;
  color: $primaryFontColor;
  background: #F0F0F0;
  font-family: $primaryFont;
}
.cms-page-view .std li {
  font-size: 1rem;
  font-style: normal;
  color: $primaryFontColor;
  font-family: $primaryFont;
}
.cms-page-view h4 {
  font-weight: 600;
}
.wrapper {
  background: transparent;
}

a, a:link {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  &:hover {
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
  }
  &:focus {
    outline: none;
  }
}

a:focus {
  color: currentColor;
}

.btn.btn-primary {
  &:hover {
    background-color: $secondary;
    border-color: $secondary;
  }
}

.footer, .footer button,
.header-language-container, .page-header,
body, button, input, select, table, textarea {
  font-family: $primaryFont;
}

img, media {
  max-width: 100%;
}

.page {
  position: relative;
  z-index: 0;
  padding-top: 88px;
}
#header {
  a, .select2-selection__rendered {
    @media screen and (max-width: 1024px) {
      font-size: 14px;
    }
    &:hover, &:focus {
      color: $secondary;
      text-decoration: none;
    }
  }
}
.main-container, .footer-container {
  padding: 0;
}
.page-header-container.container, div.container {
  max-width: unset;
  padding-left: 4em;
  padding-right: 4em;
  @media screen and (max-width: 992px) {
    padding-left: 2em;
    padding-right: 2em;
  }
  @media screen and (max-width: 768px) {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.col2-left-layout .col-main {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #000 transparent transparent transparent;
}
.page-header {
  position: fixed;
  width: 100%;
  z-index: 30;
  top: 0;
  background: #F6F5F3;
  #header-nav {
    border: 0;
    &.skip-active {
      background: transparent;
      animation: 1s 1ms slide-right--100 cubic-bezier(.32,.24,.15,1) forwards;
      transform: translate(-100%);
    }
  }
  .main-nav {
    border: 0;
    position: static;
    padding: 0;
  }
  .fixed-header .menu > li > a, .main-nav .menu > li > a {
    font-weight: 600;
    outline: none;
    &:hover {
      color: $secondary;
      text-decoration: none;
    }
  }
  .fixed-header .menu > li.active > a, .main-nav .menu > li.active > a {
    color: $secondary;
    text-decoration: none;
  }
  .logo {
    float: none;
    max-width: none;
    min-height: 0;
    width: auto;
    padding: 0;
    display: inline-block;
    img {
      margin: 0 auto;
      margin-top: 10px;
      width: 160px;
    }
  }
  .page-header-container .store-language-container {
    float: none;
    max-width: unset;
    padding: 0;
    position: relative;
    top: 2px;
  }
  #header-account {
    padding: 0;
  }
  .account-cart-wrapper {
    position: relative;
  }
  .links-column {
    .skip-link {
      font-weight: 600;
      display: block;
      text-transform: uppercase;
      padding: 2em 0;
    }
    & > * {
      font-weight: 600;
      & + * {
        margin-left: 2.5em;
        display: block;
        position: relative;
        @media screen and (max-width: 1024px) {
          margin-left: 1.5em;
        }
        @media screen and (max-width: 768px) {
          margin-left: 0.7em;
        }
      }
    }
  }
}
.skip-cart .count {
  background-color: #000;
  border-radius: 12px;
  display: inline-block;
  position: absolute;
  z-index: 5;
  width: 17px;
  height: 17px;
  color: #fff !important;
  margin-left: 0;
  font-size: 11px;
  line-height: 16px;
  top: 25px;
  right: -12px;
  &:after, &:before {
    display: none !important;
  }
  @media screen and (max-width: 768px) {
    right: -4px;
  }
}
.fixed-header .menu > li, .main-nav .menu > li {
  padding: 0;
  a {
    padding: 2em 0;
  }
  & + li {
    margin-left: 2.5em;
    @media screen and (max-width: 1024px) {
      margin-left: 1.2em;
    }
  }
}

.fixed-header .menu > li .nav-sublist-dropdown .container, .main-nav .menu > li .nav-sublist-dropdown .container {
  height: 100%;
}
@keyframes slide-right--100 {
  0% {
    transform: translate(-100%)
  }
  to {
    transform: translate(0);
    opacity: 1;
  }
}
@keyframes slide-left--100 {
  0% {
    transform: translate(0)
  }
  to {
    transform: translate(-100%)
  }
}
.fixed-header .menu > li.menu-static-width,
.main-nav .menu > li.menu-static-width {
  .nav-sublist-dropdown,
  .nav-sublist-dropdown {
    left: 0;
    -webkit-box-shadow: 0 0 0 0 transparent;
    -moz-box-shadow: 0 0 0 0 transparent;
    box-shadow: 0 0 0 0 transparent;
    background-color: transparent;
    max-height: calc(100vh - 88px);
    padding: 0;
    font-weight: 600;
    width: auto !important;
    height: 100vh;
    max-width: none !important;
    border: 0;
    opacity: 0;
    display: block !important;
    animation: slide-left--100 .3s ease forwards;
    transform: translate(-100%);
    sup {
      margin-left: 5px;
      opacity: 0;
    }
    .block-categories > ul,
    ul.top-actions,
    ul.designers {
      padding-bottom: 70px;
      li {
        & + li {
          margin-top: 10px;
        }
      }
    }
    .block-categories {
      margin-top: 3em;
      ul {
        & > li {
          a.level1 {
            text-transform: none;
            line-height: 1;
          }
        }
      }
    }
    .mega-columns {
      font-family: $secondaryFont;
      font-size: 1.125rem;
      height: 100%;
      .col {
        height: 100%;
        width: 33.333vw;
        position: absolute;
        left: 0;
        padding: 0;
        overflow: hidden;
        opacity: 0;
        &.left {
          opacity: 1;
          position: relative;
        }
        &.middle, &.right {
          animation: slide-left--100 .3s ease forwards;
        }
        &.middle.opened {
          left: 33.33vw;
          opacity: 1;
          animation: 1s 1ms slide-right--100 cubic-bezier(.32,.24,.15,1) forwards;
          transform: translate(-100%);
        }
        &.right.opened {
          left: 66.666vw;
          opacity: 1;
          animation: 1s 1ms slide-right--100 cubic-bezier(.32,.24,.15,1) forwards;
          transform: translate(-100%);
          @media screen and (max-width: 768px) {
            left: 0;
            z-index: 999999;
            width: 100%;
            max-width: 100%;
          }
        }
        &:not(.designer-quickview-container) {
          background: #EFEFEF;
          padding: 3em 3rem 1.5rem 3rem;
          z-index: 1;
          &.left {
            z-index: 2;
          }
        }
        .designers-column {
          height: 100%;
          overflow-y: scroll;
          &:after {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #EFEFEF);
            bottom: 1.5rem;
            content: "";
            display: block;
            height: 20%;
            left: 0;
            pointer-events: none;
            position: absolute;
            width: 100%;
          }
          .designers {
            overflow: auto;
          }
        }
      }
    }
    li {
      a:hover, a.active {
        sup {
          opacity: 1;
        }
      }
    }
    .column-title {
      font-size: 1.125em;
      font-weight: bold;
    }
    .designers {
      font-size: 1rem;
      font-weight: 400;
      margin-top: 10px;
      font-family: $primaryFont;
      li {
        & + li {
          margin-top: 0.5em;
        }
      }
    }
  }
  &.opened {
    & > a {
      color: $secondary;
    }
    & > .nav-sublist-dropdown,
    & > .nav-sublist-dropdown {
      left: 0;
      opacity: 1;
      animation: 1s 1ms slide-right--100 cubic-bezier(.32,.24,.15,1) forwards;
      transform: translate(-100%);
    }
  }
}

.body-dark {
  overflow: hidden;
  &:before {
    content: " ";
    background: rgba(0, 0, 0, 0.3);
    bottom: 0;
    height: 100vh;
    left: 100%;
    opacity: 1;
    position: fixed;
    right: 0;
    top: 0;
    left: 33.33%;
    z-index: 1;
    width: 100%;
  }
  &.body-dark-small {
    &:before {
      left: 66.66%;
    }
  }
  &.body-dark-zero {
    &:before {
      left: 100%;
    }
  }
}

.main-nav .menu > li.menu-static-width .nav-sublist-dropdown .btn-primary sup, .main-nav .menu > li.menu-static-width .nav-sublist-dropdown .btn-primary sup {
  opacity: 1;
}

.designer-quickview-container {
  height: 100%;
  overflow: hidden;
  background: #FFFFFF;
  position: absolute;
  right: 0;
  max-width: 33.333vw;
  .featured-product {
    font-weight: 700;
  }
  & > div {
    height: 100%;
  }
  .middle {
    overflow: auto;
    overflow-x: hidden;
    max-height: fit-content;
    margin-left: -5px;
    margin-right: -5px;
    .products-grid.row {
      margin: 0;
      .product .product-image-area {
        margin-bottom: 5px;
      }
      & > div {
        padding-right: 5px;
        padding-left: 5px;
      }
      .product-info {
        padding: 0 1.5rem;
        text-align: center;
        .h5 {
          font-size: 0.9rem;
          font-weight: 700;
          margin: 0;
        }
        .price-box {
          line-height: 1;
          padding-bottom: 10px;
          .special-price {
            padding-left: 0.2em;
          }
          .price {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
  a.btn-block {
    padding: 1.5em 0 !important;
  }
  .bottom {
    margin-top: auto;
  }
}

.fixed-header .menu > li.menu-full-width .nav-sublist-dropdown .container ul > li > a, .main-nav .menu > li.menu-full-width .nav-sublist-dropdown .container ul > li > a, .fixed-header .menu > li.menu-static-width .nav-sublist-dropdown .container ul > li > a, .main-nav .menu > li.menu-static-width .nav-sublist-dropdown .container ul > li > a {
  padding: 0;
  display: inline-block;
  outline: none;
  font-size: 1.125em;
  &.active, &:hover {
    text-decoration: none;
    color: currentColor;
    outline: none;
  }
}

.main-nav .menu > li.menu-static-width .nav-sublist-dropdown .container ul.designers > li > a {
  font-size: 1rem;
  cursor: pointer;
}

#header-nav .menu > li:nth-child(1) {
  .nav-sublist-dropdown {
    //display: block !important;
  }
}

.nav-sublist-dropdown {
  .mega-columns .col {
    animation: fade-in .3s ease-out forwards;
    &:after {
      content: "";
      animation: fade-in .3s ease-out forwards;
    }
    &.closed {
      right: 100%;
      animation: fade-in .3s ease-out forwards;
    }
  }
  &.loading {
    .mega-columns .col {
      &:after {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.75);
        animation: fade-in .3s ease-out forwards;
        z-index: 1;
      }
    }
  }
}
.skip-links {
  border: 0;
}
.skip-link {
  width: auto;
  text-transform: none;
  padding: 0;
  background: transparent;
  float: none;
  height: auto;
  color: currentColor;
  text-align: unset;
  line-height: unset;
  border: 0 !important;
}

.footer-container {
  background: #F6F5F3;
  z-index: -1;
}
.footer {
  border: 0;
  padding: 5rem;
  font-size: 0.875rem;
  @media screen and (max-width: 1400px) {
    padding: 5rem 3rem;
  }
  @media screen and (max-width: 1200px) {
    padding: 5rem 2rem;
  }
  @media screen and (max-width: 992px) {
    padding: 3rem 1rem;
  }
  @media screen and (max-width: 767px) {
    padding: 1rem;
  }
  @media screen and (max-width: 576px) {
    padding: 0;
    padding-top: 20px;
  }
  & .col {
    @media screen and (max-width: 1200px) {
      max-width: 33.33%;
      width: 33.33%;
      flex: 33.33%;
    }
    @media screen and (max-width: 767px) {
      max-width: 50%;
      width: 50%;
      flex: 50%;
    }
    @media screen and (max-width: 576px) {
      max-width: 100%;
      width: 100%;
      flex: 100%;
    }
  }
  .links {
    a {
      color: currentColor;
      &:hover {
        color: currentColor;
      }
    }
  }
  .block, .links {
    .block-title {
      padding: 0;
      border: 0;
      color: currentColor;
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 1em;
      span, strong {
        text-transform: uppercase;
      }
      @media screen and (max-width: 576px) {
        margin-bottom: 0.3em;
      }
      strong {
        font-weight: inherit;
      }
    }
  }
  address {
    padding: 0;
    color: currentColor;
    border: 0;
    font-size: 0.875rem;
    margin: 0;
  }
  .links, .block-subscribe {
    width: auto;
    font-size: 0.875rem;
    float: none;
    padding: 0;
  }
  .footer-menu {
    li {
      & + li {
        &:before {
          content: "/";
          margin: 0 1em;
          @media screen and (max-width: 992px) {
            margin: 0 0.5em;
          }
        }
      }
    }
  }
  .block-subscribe {
    input {
      border: 0;
      border-bottom: 1px solid;
      background: transparent;
      padding-left: 0;
      padding-right: 0;
      max-width: 200px;
    }
    .input-box,
    .actions {
      float: none;
      width: auto;
      margin-top: 1em;
      @media screen and (max-width: 576px) {
        margin-top: 0;
        margin-bottom: 1em;
      }
    }
  }
}

.block-title h2, .block-title h3, .block-title strong {
  color: currentColor;
  font-weight: inherit;
  font-size: inherit;
  line-height: unset;
  font-family: inherit;
}

.breadcrumb {
  font-size: 1rem;
}

.breadcrumbs {
  margin: 0;
}

.form-language {
  .select2-container {
    .select2-selection--single {
      background: transparent;
      border: 0;
      text-transform: uppercase;
    }
  }
}

.block {
  .block-title {
    border: 0;
    padding: 0;
    margin: 0;
  }
}

.global-site-notice {
  position: fixed;
}
.text-search, .text-login {
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.skip-link .icon, .open-account .icon {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}
.skip-nav {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}
.skip-link:not(.skip-active):hover {
  opacity: 1;
  color: $secondary;
  text-decoration: none;
}
#header-search {
  position: fixed;
  height: 100vh;
  left: 0;
  display: block !important;
  top: -100vh;
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  z-index: 99;
  right: unset;
  overflow: hidden;
  bottom: -100%;
  .form-search {
    position: relative;
  }
  &.skip-active {
    top: 0;
  }
  .contents {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 520px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 768px) {
      width: 80%;
    }
    form {
      margin: auto 0;
      input {
        background: transparent;
        border: 0;
        padding-left: 0;
        padding-right: 0;
        border-bottom: 1px solid $primary;
        font-family: inherit;
        font-size: 1.5rem;
        &:focus {
          outline: none;
        }
      }
      label {
        display: block;
      }
      button {
        svg {
          -webkit-transform: rotate(-180deg);
          -moz-transform: rotate(-180deg);
          -ms-transform: rotate(-180deg);
          -o-transform: rotate(-180deg);
          transform: rotate(-180deg);
          max-width: 30px;
          height: auto;
        }
      }
    }
    .categories {
      & > * + * {
        margin-left: 1em;
      }
    }
    .close {
      left: 30%;
      padding: 1em 0;
      margin-left: auto;
      font-size: 3rem;
      font-weight: 100;
      line-height: 16px;
      border: 0;
      @media screen and (max-width: 1024px) {
        left: unset;
        right: 0;
      }
    }
    .search-autocomplete {
      position: absolute !important;
      bottom: 1px !important;
      top: unset !important;
      &:before {
        display: none;
      }
      ul {
        border-color: $primary !important;
        border-radius: 0 !important;
        li {
          color: currentColor;
          font-size: inherit;
          &.selected {
            background: $primary;
            color: #FFFFFF;
          }
        }
      }
    }
  }
}

.products-in-brand {
  background: #FFFFFF;
}

.buttons-set {
  border: 0;
  padding: 1em 0 0 0;
  text-align: unset;
  margin: 0;
}

.block-customer-address {
  font-size: inherit;
  .block-title {
    border-bottom: 1px solid;
    margin-bottom: 1em;
    strong {
      padding: 0;
      background: transparent;
    }
    i {
      color: $secondary;
      margin-right: 7px;
    }
  }
}

#goTop {
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: block;
  cursor: pointer;
  z-index: 999;
  width: 48px;
  height: 48px;
  line-height: 42px;
  font-size: 28px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid $primary;
  text-align: center;
  &.hide {
    bottom: -200px;
  }
  i {
    color: currentColor;
    &:before {
    }
  }
  &:hover {
    background: $primary;
    color: #FFFFFF;
  }
}

.page-title h1, .page-title h2, .product-name h1, .product-name .h1 {
  color: currentColor;
  text-transform: none;
  font-size: 2.6rem;
  @media screen and (max-width: 768px) {
    font-size: 2.2rem;
  }
  @media screen and (max-width: 576px) {
    font-size: 2rem;
  }
}

.fixed-header .menu > li > a:after, .main-nav .menu > li > a:after {
  display: none;
}

#v-cookielaw.v-bar.v-bottom {
  border: 0;
}

.fancybox-skin {
  border-radius: 0;
}

.fancybox-slide--iframe .fancybox-content {
  max-height: 90vh;
  overflow: hidden;
  max-width: 1000px;
}

.main.container {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.read-more-text,
.category-description {
  line-height: 2;
  margin: 0 auto 2em auto;
  clear: both;
  max-width: 1600px;
  & + [data-readmore-toggle] {
    font-size: 3rem;
    font-weight: 100;
    line-height: 1;
    right: 0;
    text-align: right;
    top: -0.5em;
    position: relative;
    &:hover {
      color: $secondary;
      text-decoration: none;
    }
  }
}

#header-account.skip-active,
#header-cart.skip-active {
  margin: 0;
  border: 1px solid #FFFFFF;
  background: #FFFFFF;
  width: auto;
  min-width: 320px;
  max-width: 80vw;
  right: 0;
  top: 100%;
  position: absolute;
  z-index: 10000000;
  &:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 11px 14px 11px;
    border-color: transparent transparent #FFFFFF transparent;
    position: absolute;
    top: -15px;
    right: 20px;
  }
}

#header-account {
  ul {
    li {
      display: block;
      a {
        display: block;
        color: currentColor;
        padding: 7px 0;
        &:hover {
          color: currentColor;
        }
      }
    }
  }
}

.block.follow-us {
  ul {
    li {
      a {
        display: inline-block;
        background-repeat: no-repeat;
        text-indent: -999px;
        overflow: hidden;
        width: 30px;
        height: 30px;
        background-color: $primary;
        mask-size: contain;
        -webkit-mask-size: contain;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        mask-position: center;
        -webkit-mask-position: center;
        &:hover {
          background-color: $secondary;
        }
        &.icon-facebook {
          mask-image: url("../images/facebook.svg");
          -webkit-mask-image: url("../images/facebook.svg");
        }
        &.icon-instagram {
          mask-image: url("../images/instagram.svg");
          -webkit-mask-image: url("../images/instagram.svg");
        }
        &.icon-youtube {
          mask-image: url("../images/youtube.svg");
          -webkit-mask-image: url("../images/youtube.svg");
        }
        &.icon-twitter {
          mask-image: url("../images/twitter.svg");
          -webkit-mask-image: url("../images/twitter.svg");
        }
      }
      & + li {
        margin-left: 1em;
      }
    }
  }
}


.grecaptcha-badge {
  transform: scale(0.5) !important;
}

/* NUOVE ICONE */
.fa-heart:before {
  content: " " !important;
  background: url("../images/like.svg") center center no-repeat;
  background-size: 100%;
  display: block;
  width: 25px;
  height: 30px;
  margin: 0 auto;
}
.fa-eye:before {
  content: " " !important;
  background: url("../images/quick-view.svg") center center no-repeat;
  background-size: 100%;
  display: block;
  width: 25px;
  height: 30px;
  margin: 0 auto;
}
/* MENU MOBILE */
.skip-link.skip-active {
  background: transparent;
}
#header-nav {
  .back-menu {
    cursor: pointer;
    margin-left: 2.5rem;
    margin-top: 1rem;
    position: absolute;
    top: 0;
    z-index: 10000;
    height: 30px;
    width: 30px;
    &:before {
      content: " ";
      background: url("../images/arrow.svg") center center no-repeat;
      background-size: 100%;
      display: block;
      width: 16px;
      height: 30px;
      margin: 0 auto;
    }
  }
  .col.middle {
    .back-menu {
      margin-left: 0;
      &:before {
        left: -6px;
        position: relative;
      }
    }
  }
}
.designer-quickview-container {
  .back-menu-designer {
    cursor: pointer;
    margin-left: 2.5rem;
    margin-top: 1rem;
    position: absolute;
    top: 0;
    z-index: 10000;
    height: 30px;
    width: 30px;
    right: 2.3rem;
    &:before {
      content: " ";
      background: url("../images/close.svg") center center no-repeat;
      background-size: 100%;
      display: block;
      width: 16px;
      height: 30px;
      margin: 0 auto;
    }
    @media screen and (max-width: 768px) {
      display: block;
      left: -14px;
      right: unset;
      &:before {
        background: url("../images/arrow.svg") center center no-repeat;
      }
    }
  }
  #v-cookielaw {
    display: none !important;
  }
}
#header-nav {
  .close-menu {
    cursor: pointer;
    right: 0;
    margin-right: 3rem;
    margin-top: 1rem;
    position: absolute;
    top: 0;
    z-index: 10000;
    height: 30px;
    width: 30px;
    &:before {
      content: " ";
      background: url("../images/close.svg") center center no-repeat;
      background-size: 100%;
      display: block;
      width: 16px;
      height: 30px;
      margin: 0 auto;
      position: relative;
      right: -6px;
    }
    @media screen and (max-width: 768px) {
      display: block;
      left: 2.1rem;
      right: unset;
      &:before {
        background: url("../images/arrow.svg") center center no-repeat;
      }
    }
  }
  .close-menu-uno {
    cursor: pointer;
    right: 0;
    margin-right: 3rem;
    margin-top: 1rem;
    position: absolute;
    top: 0;
    z-index: 10000;
    height: 30px;
    width: 30px;
    &:before {
      content: " ";
      background: url("../images/close.svg") center center no-repeat;
      background-size: 100%;
      display: block;
      width: 16px;
      height: 30px;
      margin: 0 auto;
      position: relative;
      right: -6px;
    }
    @media screen and (max-width: 768px) {
      display: block;
      left: 2.1rem;
      right: unset;
      &:before {
        background: url("../images/arrow.svg") center center no-repeat;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .body-dark {
    &:before {
      display: none;
    }
  }
  #header-nav {
    width: 100%;
    background-color: #EFEFEF !important;
    position: absolute;
    top: 80px;
    left: 0;
    padding: 3em;
    height: calc(100vh - 80px);
  }
  .main-nav .menu > li.menu-static-width .nav-sublist-dropdown, .main-nav .menu > li.menu-static-width .nav-sublist-dropdown {
    top: 0;
  }
  .main-nav .menu > li.menu-static-width .nav-sublist-dropdown .mega-columns .col, .main-nav .menu > li.menu-static-width .nav-sublist-dropdown .mega-columns .col {
    width: 100vw;
  }
  .fixed-header .menu > li.menu-static-width.opened > .nav-sublist-dropdown, .fixed-header .menu > li.menu-static-width.opened > .nav-sublist-dropdown, .main-nav .menu > li.menu-static-width.opened > .nav-sublist-dropdown, .main-nav .menu > li.menu-static-width.opened > .nav-sublist-dropdown {

  }
  .main-nav .menu > li {
    display: block;
    margin-left: 0 !important;
    padding: 0;
    a {
      padding: 0.5rem;
      text-transform: none;
      font-size: 18px !important;
    }
  }
  .mega-columns .col.middle.opened, .main-nav .menu > li.menu-static-width .nav-sublist-dropdown .mega-columns .col.middle.opened {
    left: 0;
    opacity: 1;
    z-index: 100000;
  }
  .skip-nav {
    .icon {
      background: url(../images/menu.svg) center center no-repeat;
      background-size: 100%;
    }
    .label {
      display: none;
    }
    &.skip-active .icon {
      background: url(../images/close.svg) center center no-repeat;
      background-size: 100%;
    }
  }
  .skip-nav:not(.skip-active):hover .icon {
    background-position: center center;
  }
  .skip-search {
    .icon {
      background: url(../images/search.svg) center center no-repeat;
      background-size: 80%;
    }
  }
  .skip-search:not(.skip-active):hover .icon {
    background-position: center center;
  }
  .open-account {
    .icon {
      background: url(../images/user.svg) center center no-repeat;
      background-size: 80%;
      width: 30px;
      height: 30px;
    }
  }
  .header-minicart .label:before {
    content: " ";
    color: transparent;
    background: url(../images/cart.svg) center center no-repeat;
    background-size: 30px;
    width: 30px;
    height: 30px;
  }
}
.new-users {
  @media screen and (max-width: 768px) {
    margin-bottom: 18px;
  }
}
.new-users > div, .registered-users > div {
  padding: 3rem;
  @media screen and (max-width: 768px) {
    padding: 2rem;
  }
  @media screen and (max-width: 576px) {
    padding: 2rem 1rem;
  }
}
.header-minicart {
  .icon {
    background-image: url("../images/cart.svg");
    background-size: 85% !important;
    background-position: center center !important;
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
  .label {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}

.form-language {
  @media screen and (max-width: 768px) {
    .select2-selection__rendered {
      background-image: url("../images/languages.svg");
      background-size: 89%;
      background-position: center center;
      color: transparent !important;
      padding: 0 !important;
      width: 30px;
      background-repeat: no-repeat;
    }
    .select2-selection__arrow {
      display: none;
    }
  }
}
@media screen and (max-width: 768px) {
  .select2-container .select2-dropdown {
    width: 40px;
    left: -5px;
    top: 5px;
  }
  .menu-left-mobile {
    max-width: 130px;
    margin-top: 4px;
    .skip-links {
      display: inline-block;
      margin-right: 10px;
      float: left;
      /*margin-top: 10px;
      margin-bottom: 10px;*/
    }
  }
}
.cms-index-index {
  .recent-posts {
    margin-left: -5px;
    margin-right: -5px;
  }
  .posts-slider {
    margin:0;
    .col {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

/* Loader css */
#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  margin: -50px 0 0 -50px;
  background-image: url("../images/logo-circle.svg");
  background-size: 100%;
}

#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background: #F0F0F0;
  z-index: 1000;
}

#loader-wrapper .loader-section.section-left {
  left: 0;
}

#loader-wrapper .loader-section.section-right {
  right: 0;
}
#loader {
  z-index: 1001; /* anything higher than z-index: 1000 of .loader-section */
}

.div-logo {
  @media screen and (max-width: 768px) {
    width: 100px;
    padding: 0;
    position: absolute;
    left: 50%;
    margin-left: -50px;
    z-index: 2;
    cursor: pointer;
  }
}
.skip-search-mobile {
  display: none;
  @media screen and (max-width: 768px) {
    display: inline-block;
  }
}
@media screen and (max-width: 768px) {
  .skip-search-desktop {
    display: none !important;
  }
}

.links-column {
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: -2px;
  }
}
.designers-column::-webkit-scrollbar-track, #filters-container .block-content::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: transparent;
}
.designers-column::-webkit-scrollbar, #filters-container .block-content::-webkit-scrollbar {
  width: 0px;
  background: transparent
}
.designers-column::-webkit-scrollbar-thumb, #filters-container .block-content::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.designers-column, #filters-container .block-content {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
  &::-webkit-scrollbar {
    width: 0;
    background: transparent; /* Chrome/Safari/Webkit */
  }
}

/* Loaded */
.loaded #loader-wrapper .loader-section.section-left {
  -webkit-transform: translateX(-100%);  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(-100%);  /* IE 9 */
  transform: translateX(-100%);  /* Firefox 16+, IE 10+, Opera */
}
.loaded #loader-wrapper .loader-section.section-right {
  -webkit-transform: translateX(100%);  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(100%);  /* IE 9 */
  transform: translateX(100%);  /* Firefox 16+, IE 10+, Opera */
}
.loaded #loader {
  opacity: 0;
}
.loaded #loader-wrapper {
  visibility: hidden;
}
.loaded #loader {
  opacity: 0;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.loaded #loader-wrapper .loader-section.section-right,
.loaded #loader-wrapper .loader-section.section-left {

  -webkit-transition: all 0.4s 0.4s ease-out;
  transition: all 0.4s 0.4s ease-out;
}
.loaded #loader-wrapper {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);

  -webkit-transition: all 0.4s 0.8s ease-out;
  transition: all 0.4s 0.8s ease-out;
}
/******/


.popup-footer {
  width: 100%;
  max-width: 800px;
  padding: 2rem;
  line-height: 18px;
  .h1_footer {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 30px;
  }
}
.view-all {
  margin-bottom: 20px;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 20px;
}

.col-left ul {
  padding-top: 3rem;
  line-height: 2;
}

.manufacturer-index-view {
  .col-main {
    width: 100%;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: unset !important;
  -webkit-text-fill-color: black !important;
  -webkit-box-shadow: none !important;
  color: #000;
  background-color: rgb(255, 255, 255) !important;
}

.full-link-news {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 2;
  cursor: pointer;
}
.post.post-blog .postBanner {
  &:before {
  -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  &:hover:before {
    opacity: 0.7;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
}

.cms-index-index {
  .brand .btn {
    padding: 0;
    border: 0;
    &:hover, &:focus {
      background-color: transparent;
      color: inherit;
    }
  }
  .brand h3.text-dark {
    color: #343a40 !important;
  }
  .brand .btn-outline-dark {
    color: #343a40;
    
    border: 1px solid #343a40;
    padding: 8px 10px;
    &:hover, &:focus {
      color: #343a40;
    }
  }
  .brand .btn-outline-light {
    color: #f8f9fa;
    border: 1px solid #f8f9fa;
    padding: 8px 10px;
    &:hover, &:focus {
      color: #f8f9fa;
    }
  }
  .full-link-brand {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    cursor: pointer;
  }
}

.header-minicart {
  .btn-remove {
    width: 25px;
    height: 25px;
    right: unset;
    left: -13px; 
    top: 42px;
  }
}
.header-minicart .mini-products-list li .product-details {
  position: relative;
  margin-left: 10px;
}
.amshopby-advanced {
  & .no-display-current, & .no-display {
    display: block;
  }
}
.hidden-mobile {
  display: block;
}
.hidden-desktop {
  display: none;
}

.soldout {
    width: 100%;
    display: block;
    background: black;
    color: white;
    padding: 3px 0;
    position: absolute;
    bottom: 0;
    left: 0;
}

/* Banner black header */
.black-banner-top {
  background-color:#000;
  color:  #F6F5F3;
  position: relative;
  padding: 10px 15px;
  .banner-text {
    font-family: "Lato", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.3em;
  }
  .btn-banner-close {
    position: absolute;
    right: 20px;
    top: 0px;
    cursor: pointer;
    img {
      width: 20px;
      height: 20px;
    }
  }
}

/*.menu-all-pages-container {
  .menu {
    .menu-static-width {
      display: none;
    }
    .display-mobile {
      display: none;
    }
  }
}*/