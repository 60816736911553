@media (min-width: 768px) {
  .main-container,
  .footer-container,
  .top-container,
  .page-header {
    max-width: none;
    padding: 0;
  }
  #header-search:not(.skip-active) {
    display: none;
  }
  .products-grid--max-4-col > li:nth-child(even), .products-grid--max-4-col > li:nth-child(3n),
  .products-grid--max-4-col > li {
    margin: 0;
  }
}

@media screen and (max-width: 1024px) {
  .page-header-container.container, div.container {
    max-width: unset;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.toolbar-mobile-class {
  .hidden-mobile {
    display: none !important;
  }
  .hidden-desktop {
    display: block !important;
  }
  .filter-toolbar {
    height: 95px;
  }
  #pop-up-country {
    display: block !important;
    width: calc(100% + 30px);
    max-width: none;
    background-color: #f6f5f3;
    margin-left: -15px;
    height: 0;
    overflow: hidden;
    &.active {
      height: auto;
      overflow: visible;
    }
    .select-country-link {
      display: none !important;
    }
    label {
      font-size: 13px;
    }
    .select2-container {
      width: 250px !important;
      font-size: 13px;
    }
    .btn {
      font-size: 13px;
    }
    .actions.text-right {
      text-align: left !important;
    }
  }
  .popup-country .block-customer-address li + li {
    margin-top: 10px;
  }
  .select-country-link {
    line-height: 23px;
    padding-left: 37px;
    font-size: 13px;padding-top: 10px;
    &:before {
      width: 24px;
      height: 23px;
    }
  }
  .popup-country .block-customer-address {
    padding: 15px 0;
    min-height: unset;
  }
}

@media screen and (max-width: 768px) {
  .menu-all-pages-container {
    .menu {
      .display-mobile {
        display: block;
      }
    }
  }
  .filter-toolbar {
    height: 115px;
  }
  .filters {
    width: 100%;
  }
  .block .block-title {
    padding-left: 0 !important;
  }
  .recent-blog-block-wide.block .block-title {
    padding-left: 15px !important;
  }
  .fancybox-content {
    padding: 10px;
  }
  .popup-country .select-country-link > span {
    font-size: 1.5rem !important;
  }
  #filters-container {
    min-width: unset;
    width: 100%;
    max-width: 400px;
  }
  .category-image-brand img {
    margin-bottom: 20px;
  }
  .col2-left-layout .col-main {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .breadcrumb {
    font-size: 0.8rem;
  }
  .brand-desc p {
    line-height: 1.5;
  }
  .hidden-mobile {
    display: none !important;
  }
  .hidden-desktop {
    display: block !important;
  }
  .filter-toolbar {
    height: 95px;
  }
  #pop-up-country {
    display: block !important;
    width: calc(100% + 30px);
    max-width: none;
    background-color: #f6f5f3;
    margin-left: -15px;
    height: 0;
    overflow: hidden;
    &.active {
      height: auto;
      overflow: visible;
    }
    .select-country-link {
      display: none !important;
    }
    label {
      font-size: 13px;
    }
    .select2-container {
      width: 250px !important;
      font-size: 13px;
    }
    .btn {
      font-size: 13px;
    }
    .actions.text-right {
      text-align: left !important;
    }
  }
  .popup-country .block-customer-address li + li {
    margin-top: 10px;
  }
  .select-country-link {
    line-height: 23px;
    padding-left: 37px;
    font-size: 13px;
    &:before {
      width: 24px;
      height: 23px;
    }
  }
  .popup-country .block-customer-address {
    padding: 15px 0;
    min-height: unset;
  }
}
@media screen and (max-width: 572px) {
  .cart-forms, .cart-totals-wrapper, .crosssell {
    min-width: auto;
}
#discount-coupon-form, .cart .giftcard {
  min-width:auto;
}
.cart-table .product-cart-image {
  min-width: auto;
}
#shopping-cart-table {
  tbody {
    td.text-right {
      //padding:0;
      .cart-links {
        display:none;
      }
    }
  }
}
.cart-table .cart-footer-actions {
  text-align: center;
  button {
    float:none !important;
    margin: 5px 0;
    &.float-left {
      float:none !important;
    }
  }
}
}
@media screen and (max-width: 480px) {
  .black-banner-top .banner-text {
    font-size:14px;
  }
  .page-header .links-column > * + * {
    margin-left: 0.2em;
    transform: scale(0.9);
  }
  .menu-left-mobile .skip-links, .skip-search-mobile {
    margin-right: 8px;
    transform: scale(0.9);
  }
  .page-header .links-column > div {
    top: -2px;
  }
  .page-header-container .links-column {
    padding-right: 10px;
  }
  .menu-left-mobile {
    padding-left: 10px;
  }
  .products-grid .product .product-name, .products-list .product .product-name {
    font-size: 0.9rem;
    line-height: 1.2;
  }
  .page-header .page-header-container .store-language-container {
    top: 0;
  }
}
@media screen and (max-width: 320px) {
  .page-header-container .links-column, .menu-left-mobile {
    padding-right: 5px;
  }
  .page-header .logo img {
    width: 80px;
  }
}