body.customer-account {
  .main.container {
    margin: 2em 0;
  }
}

.account-create {
  .fieldset {
    border: 1px solid;
    .customer-name-middlename, .fields {
      display: flex;
      flex-wrap: wrap;
      & > * {
        & + * {
          margin-left: 1em;
        }
      }
    }
  }
}

.box-account.box-info {
  border: 1px solid;
  margin: 0;
  .box-head {
    margin: 0;
    text-transform: uppercase;
    border-color: $primary;
    h2 {
      font-size: 1.5em;
    }
  }
}

.block-account {
  border: 1px solid;
  padding: 2em 1em;
  ul {
    li {
      margin: 0;
      border-bottom: 1px solid;
      font-family: inherit;
      a {
        display: block;
        color: currentColor;
        text-transform: none;
        padding: 5px 0 6px 0;
        &:hover {
          color: currentColor;
          font-weight: 600;
          text-decoration: none;
        }
      }
      &.current {
        a {
          strong {
            color: currentColor;
            font-weight: 600;
          }
        }
      }
    }
  }
}

#wishlist-table.clean-table,
#wishlist-table.clean-table thead th,
.dashboard .box-account p, .dashboard .box-account address {
  font-family: inherit;
  font-style: normal;
}

.my-wishlist {
  .buttons-set {
    text-align: right;
  }
  .remove {
    font-size: 2em;
  }
}

#wishlist-table.clean-table {
  .price-box {
    font-family: inherit;
    .price {
      color: currentColor;
    }
  }
  tr {
    border-bottom: 1px solid;
    td {
      border-color: $primary;
    }
  }
  thead {
    tr {
      th {
        border-bottom: 1px solid;
      }
    }
  }
}

#wishlist-table.clean-table td:not(.customer-wishlist-item-info) {
  vertical-align: middle;
}