body.blog-post-view,
body.blog-cat-view,
body.blog-index-list {
  .main.container {
    max-width: 1400px !important;
  }
  .page-content {
    margin-top: 2em;
  }
}

.post {
  &.post-teaser {
    position: relative;
    .postWrapper {
      padding: 0;
    }
    .postTitle {
      border: 0;
      h2 {
        font-size: 28px;
        font-weight: 500;
        letter-spacing: 0.6px;
        margin-bottom: 9px;
        line-height: 1.4;
      }
    }
    .post-date {
      min-width: 70px;
      background: #FFFFFF;
      .day {
        line-height: 2em;
        font-size: 2rem;
      }
      .month {
        text-transform: uppercase;
        background: $primary;
        color: #FFFFFF;
      }
    }
    .smartwave-blog-read-more {
      text-decoration: underline;
      text-transform: uppercase;
    }
    & + .post {
      margin-top: 3.5em;
      border-top: 1px solid;
      padding-top: 4em;
    }
  }
}

.block-title h2, .block-title h3, .block-title strong {
  text-transform: none;
}

.col-left,
.col-right {
  .block {
    .block-title {
      font-size: 1.125rem;
      padding-bottom: 15px;
      margin-bottom: 0;
      font-weight: 600;
    }
  }
}

.block-blog-categories {
  .block-title {
    font-size: 24px;
  }
  .block-content {
    border-top: 1px solid;
    .blog-category {
      a {
        color: currentColor;
        padding: 7px 0;
        margin: 0;
        font-size: inherit;
        display: inline-block;
        &:hover {
          color: $secondary;
        }
      }
      & + * {
        border-top: 1px solid;
      }
    }
  }
}

.block-blog-recent {
  .block-content {
    ul {
      li {
        padding: 1.5em 0;
        margin: 0;
        &:nth-child(1) {
          padding-top: 0;
        }
        & + * {
          border-top: 1px solid;
        }
        a,
        div {
          font-family: inherit;
          color: currentColor;
          font-size: inherit;
          font-style: inherit;
          text-align: left;
        }
        a {
          line-height: 1.1;
          font-weight: 600;
          display: block;
        }
        div.post-image {
          width: auto;
          background: transparent;
          float: none;
          margin: 0;
        }
      }
    }
  }
}

.block-blog-tags {
  position: relative;
  overflow: hidden;
  clear: both;
  .block-content {
    ul li a {
      font-family: inherit;
      color: #FFFFFF;
      line-height: 1;
      background: $primary;
      &:hover {
        background: $secondary;
        text-decoration: none;
      }
    }
  }
}

.blog_container {
  .toolbar-bottom {
    margin: 2em 0;
    border-top: 1px solid;
    border-bottom: 1px solid;
    .toolbar {
      display: flex;
      padding: 1em 0;
      margin: 0;
      .pager {
        margin-left: auto;
        .pages {
          margin: 0;
          ul, ol {
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }
}

.postWrapper.postSingle {
  position: relative;
  .postTitle {
    border: 0;
    h1 {
      font-size: 1.875rem;
      font-weight: 500;
    }
  }
  .postAuthor {
    font-weight: 700;
    color: $secondary;
  }
  .share-box {
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 1em 0;
  }
  .tags {
    padding: 1em 0;
    border-bottom: 1px solid;
  }
}

body.cms-index-noroute {
  .error-content {
    padding: 80px 0 90px;
    text-align: center;
    .error-title {
      font-size: 300px;
      font-family: $secondaryFont;
      span {
        font-size: 70px;
        margin-top: -3px;
        display: block;
      }
    }
    a {
      color: $secondary;
    }
  }
}

#contactForm {
  padding: 0;
}

.contacts-index-index, .cms-contacts-result {
  .regular-title {
    border-bottom: 1px solid;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  .buttons-set {
    max-width: 0;
    padding: 0;
    margin: 0;
  }
}

#map {
  width: 100%;
  min-height: 500px;
}

.cms-page-view .std, .cms-no-route .std {
  h1 {
    color: currentColor;
  }
  p {
    color: currentColor;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    font-style: inherit;
  }
}

.about-us-images {
  & > * + * {
    margin-left: 10px;
  }
}

#pop-up-country {
  width: 100%;
  max-width: 580px;
}

#newsletter_popup {
  width: 650px;
  height: 450px;
  //background-color: #000;
  background-color: #fff;
  //color: #fff;
  color: #000;
  text-align: center;
  @media screen and (max-width: 768px) {
    padding: 20px;
    height: 500px;
  }
  .block-content {
    h2 {
      font-weight: 600;
      //color: #fff;
      color: #000;
      margin-bottom: 40px;
      &:before {
        content: " ";
        background: url("../images/e-mail-envelope.svg") no-repeat center center;
        background-size: contain;
        height: 24px;
        display: inline-block;
        width: 30px;
        margin-right: 15px;
      }
    }
  }
  h2 {
    margin-bottom: 20px;
  }
  p {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .btn-outline-dark {
    margin-top: 30px;
  }
  form {
    margin-bottom: 40px;
    input[type='text'] {
      width: calc(100% - 130px);
      border: 1px solid #fff;
      height: 50px;
      background-color: transparent;
      color: #fff;
    }
    button {
      letter-spacing: 2px;
      border: 1px solid #fff;
      box-sizing: border-box;
      width: 130px;
      height: 50px;
      &:hover, &:focus {
        background-color: #fff;
        color: #000;
      }
    }
  }
  .subscribe-bottom {
    a {
      text-decoration: underline;
      color: #fff;
    }
  }
  .input-box { 
    text-align: left;
  }
  p.required, .validation-advice {
    position: absolute;
    bottom: -20px;
    left: 0;
  }
  .fancybox-button svg path {
    fill: #fff;
  }
}
#newsletter_popup.halloween{
	h2.title {
		color: white;
		margin-bottom: 30px;
	}
	p {
		margin-bottom: 18px;
		line-height: 1.3;
	}
	h2.code{
		color: white;
		margin-bottom: 21px;
	}
}

#newsletter_popup.blackfriday{

  h2.title {
    color: #F6F5F3;
    margin-bottom: 30px;
    font-size: 35px ;
    margin-bottom: 1px;
    font-family: Lato;
    font-weight: 600;
  }
  p.subtitle {
    margin-bottom: 18px;
    line-height: 1.3;
    color: #F6F5F3;
    font-size: 25px ;
    font-family: "Times New Roman";

  }
  p.promo {
    font-size: 16px ;
    margin-bottom: -5px;
    margin-top: 5px;
    font-family: "Times New Roman";
    font-weight: bold;
  }
  h2.code{
    color: #FFF;
    margin-bottom: 25px;
    margin-top: 15px;
    font-family: "Times New Roman";
    font-size: 24px ;
    font-weight: bold;
  }
  p.footer2 {
    font-size: 14px ;
    margin: 0 0;
    font-family: Lato, Regular;
  }
}

body.cms-home .main-container {
  padding-top: 0px;
  margin-top: -1px;
}