@mixin baseAnimation() {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@mixin lineBottom() {
  margin-bottom: 10px;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    max-width: 22px;
    margin-top: 3px;
    margin-bottom: 0;
  }
  &.text-center {
    &:after {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}