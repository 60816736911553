.category-products {
  background: #EFEFEF;
  padding: 1em 7.5px;
  .col-lg-3 {
    padding: 0 7.5px;
  }

}

#filters-toggle {
  position: relative;
  &:after {
    content: "+";
    display: inline-block;
    font-weight: 100;
    font-size: 26px;
    left: unset;
    margin: 0 0 0 5px;
    top: 3px;
    border: 0;
    height: 16px;
    position: absolute;
    line-height: 16px;
  }
  &.opened {
    &:after {
      content: "-";
    }
  }
}

.filter-toolbar {
  background: #F6F5F3;
  margin-bottom: 2em;
  position: relative;
  z-index: 2;
}

.products-grid {
  li.item {
    width: auto;
  }
  .price-box {
    font-family: inherit;
    color: currentColor;
    font-size: inherit;
    .price {
      color: currentColor;
      font-family: inherit;
    }
    .special-price,
    .old-price {
      color: currentColor;
      .price {
        color: currentColor;
        font-family: inherit;
      }
    }
    .special-price {
      .price {
        font-weight: bold;
        font-size: 1.125em;
      }
    }
  }
}

.toolbar, .pager {
  font-family: inherit;
  padding: 0;
  border: 0;
  color: currentColor;
  background: transparent;
}

.pager {
  float: none;
  .pages {
    float: none;
    ul, ol {
      display: flex;
      align-items: center;
      li {
        font-family: "Lato", sans-serif;
        color: $primary;
        height: auto;
        width: auto;
        line-height: 1;
        &:hover, &:focus {
          text-decoration: none;
        }
        a {
          font-family: "Lato", sans-serif;
          color: $primary;
          cursor: pointer;
          width: 40px;
          height: 30px;
          font-size: 16px;
          position: relative;
          &:after {
            content: " ";
            width: 30px;
            position: absolute;
            bottom: -3px;
            height: 1px;
            background-color: transparent;
            display: block;
            left: 50%;
            margin-left: -15px;
          }
          &:hover, &:focus {
            text-decoration: none;
            &:after {
              background-color: $primary;
            }
          }
        }
        &.current {
          border: 0;
          background-color: transparent;
          line-height: 30px;
          width: 40px;
          height: 30px;
          font-size: 16px;
          position: relative;
          &:after {
            content: " ";
            width: 30px;
            position: absolute;
            bottom: -3px;
            height: 1px;
            background-color: #000;
            display: block;
            left: 50%;
            margin-left: -15px;
          }
        }
      }
    }
  }
}
.pages {
  .previous {
    &:before {
      border-right-color: black !important;
    }
  }
  .next {
    &:before {
      border-left-color: black !important;
    }
  }
  .next, .previous {
    color: transparent !important;
    &:before {
      border-width: 5px;
      margin-top: -5px;
    }
    &:after {
      display: none !important;
    }
    &:hover, &:focus {
      border: 0;
      &:before {
        border-width: 5px;
      }
    }
  }
}
.products-grid, .products-list {
  .product {
    overflow: hidden;
    position: relative;
    .add-to-links {
      position: absolute;
      left: 0;
      z-index: 1;
      text-align: right;
      margin: 0;
      & > li {
        & + li {
          margin-left: 10px;
        }
      }
      a {
        position: relative;
        top: -100px;
        -webkit-transition: all 0.4s;
        -moz-transition: all 0.4s;
        -ms-transition: all 0.4s;
        -o-transition: all 0.4s;
        transition: all 0.4s;
        i {
          font-size: 30px;
        }
      }
    }
    .product-label {
      position: absolute;
      top: 7px;
      z-index: 1;
      & > span {
        display: block;
        width: 40px;
        height: 40px;
        font-size: 11px;
        font-weight: 700;
        line-height: 40px;
        color: #FFFFFF;
        background-color: #000000;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: 50%;
        text-align: center;
      }
    }
    .product-image-area {
      border: 0;
      margin: 0 0 1.5em 0;
      position: relative;
      overflow: hidden;
      .product-image {
        border: 0;
        margin: 0;
      }
      &:hover {
        border-color: transparent;
      }
      .sizes {
        position: absolute;
        background: rgba(255, 255, 255, 0.85);
        bottom: -40px;
        opacity: 0;
        width: 100%;
        -webkit-transition: all 0.4s;
        -moz-transition: all 0.4s;
        -ms-transition: all 0.4s;
        -o-transition: all 0.4s;
        transition: all 0.4s;
        text-align: center;
        font-size: 1rem;
      }
    }
    .product-name {
      font-size: 1.25rem;
      font-family: inherit;
      color: currentColor;
      @media screen and (max-width: 768px) {
        font-size: 1rem;
      }
    }
    &:hover {
      .add-to-links {
        a {
          -webkit-transition: all 0.4s;
          -moz-transition: all 0.4s;
          -ms-transition: all 0.4s;
          -o-transition: all 0.4s;
          transition: all 0.4s;
          top: 0;
        }
      }
      .product-image-area {
        .sizes {
          opacity: 1;
          bottom: 6px;
          -webkit-transition: all 0.4s;
          -moz-transition: all 0.4s;
          -ms-transition: all 0.4s;
          -o-transition: all 0.4s;
          transition: all 0.4s;
        }
      }
    }
  }
  h2.product-name a, h3.product-name a, h4.product-name a, h5.product-name a, p.product-name a {
    color: currentColor;
    font-family: inherit;
    font-weight: 700;
  }
  .product-info {
    padding: 0;
    margin-bottom: 1em;
  }
}

.products-grid > li {
  width: auto !important;
  margin: 0 !important;
}

.filters-open {
  overflow: hidden;
}

div.block-layered-nav, div.amshopby-page-container, div.amshopby-filters-top {
  height: 100%;
}

#filters-container {
  height: 100vh;
  left: -100%;
  margin-top: 1em;
  position: absolute;
  z-index: 1;
  background: transparent;
  min-width: 380px;
  transition: all 0.6s ease;
  overflow: hidden;
  &:before {
    content: "";
    background: #EFEFEF;
    width: 100%;
    height: 100%;
    display: block;
    z-index: -1;
    position: absolute;
  }
  &.open {
    left: 0;
    transition: all 0.6s ease;
  }
  .block-content {
    height: calc(100%);
    padding: 2em 2em 4em 4em;
    overflow: auto;
    &:after {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 20%, #EFEFEF);
      bottom: 0;
      content: "";
      display: block;
      height: 30%;
      left: 0;
      pointer-events: none;
      position: absolute;
      width: 100%;
    }
    @media screen and (max-width: 992px) {
      padding: 2em;
    }
    @media screen and (max-width: 768px) {
      padding: 1em;
    }
  }
  .actions {
    margin: 0;
    position: absolute;
    width: 100%;
    bottom: 14px;
    a {
      background-color: #000;
      padding: 20px;
      text-transform: capitalize !important;
      font-size: 1.4rem;
    }
  }
}
div.block-layered-nav dd > ol li.level0.parent > a {
  font-weight: 800;
}
#narrow-by-list, #narrow-by-list2 {
  border: 0;
  margin-bottom: 6rem;
}
.block-layered-nav .block-content > dl > dd {
  display: block;
}
.amshopby-advanced li.active > a {
  font-weight: 800;
}
.block-layered-nav dl dd ol > li > span, .block-layered-nav dl dd ol > li > a {
  padding: 2px 15px;
}
body.catalogsearch-result-index,
body.manufacturer-index-index,
body.catalog-product-view,
body.catalog-category-view {
  .main.container {
    padding-left: 0;
    padding-right: 0;
  }
}

body.manufacturer-index-index,
body.catalog-product-view {
  .page-title.category-title {
    display: none;
  }
}

.block-layered-nav .block-content > dl {
  & > dd,
  & > dt {
    border: 0;
    padding: 0;
  }
}

.designer-search {
  background: #F6F5F3;
  input {
    background: transparent;
    border: 0;
    padding: 0;
    &:focus, &:active {
      outline: none;
      background: transparent;
    }
  }
}

.manufacturers-list {
  .list {
    grid-gap: 3rem;
    column-count: 4;
    @media screen and (max-width: 992px) {
      column-count: 3;
    }
    @media screen and (max-width: 768px) {
      column-count: 2;
    }
    & > * {
      display: block;
      flex-direction: column;
      position: relative;
      width: 100%;
      &.last + .letter {
        margin-top: 3em;
      }
    }
    .letter {
      font-family: $secondaryFont;
      break-after: avoid;
    }
  }
}

.block-layered-nav.amshopby-filters-left {
  .block-title {
    margin-bottom: 2em;
    span {
      font-family: $secondaryFont;
      font-weight: 700;
      text-transform: none;
      font-size: 20px;
    }
  }
}

div.block-layered-nav.amshopby-collapse-enabled {
  #narrow-by-list {
    .name.open {
      &::after {
        content: "-";
      }
    }
    .name.amshopby-collapsed, .name {
      &::after {
        content: "+";
      }
    }
    .name, .name.amshopby-collapsed {
      background: transparent;
      padding: 0;
      font-family: $secondaryFont;
      font-weight: 600;
      color: currentColor;
      text-transform: none;
      font-size: 20px;
      cursor: pointer;
      &::after {
        content: "+";
        display: inline-block;
        font-weight: 100;
        font-size: 26px;
        left: unset;
        margin: 0 0 0 5px;
        top: 50%;
        transform: translateY(-50%);
        border: 0;
        height: auto;
      }
    }
    .name.amshopby-collapsed, .name.open {
      &::after {
        content: "-";
      }
    }
  }
}


.block-layered-nav dl ol > li > a {
  color: #000;
  background-color: transparent;
  padding: 5px 10px;
  margin: 0;
}

#filters-toggle {
  font-weight: 600;
  padding-right: 25px;
  &:hover, &:focus {
    text-decoration: none;
  }
}

div.block-layered-nav dd {
  & + dt {
    margin-top: 1.5rem !important;
  }
  & > ol {
    padding-left: 1.5em;
    li {
      display: block;
      clear: both;
      margin-top: 5px;
      overflow: hidden;
      &:after {
        display: none;
      }
      a.amshopby-attr, a.amshopby-attr:hover, .amshopby-attr-selected {
        background: none;
        padding: 0;
        border-bottom: 1px solid transparent;
        text-transform: lowercase;
        &:hover {
          text-decoration: none;
          border-bottom: 1px solid;
        }
      }
      .amshopby-attr-selected {
        border-bottom: 1px solid;
        font-weight: 600;
        &:hover {
          text-decoration: none;
          padding: 0;
          background: transparent;
        }
      }
    }
  }
}

.currently {
  margin-left: 2em;
  display: flex;
  ul, ol {
    li {
      position: relative;
      padding-left: 20px;
      & + li {
        margin-left: 1em;
      }
    }
  }
  .link-filtro {
    margin-left: 2em;
  }
}

.btn-remove {
  border: 0;
  color: currentColor;
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  text-align: left;
  &:after {
    content: "×";
    color: currentColor;
    font-size: 20px;
    font-weight: 100;
    font-family: $primaryFont;
    position: relative;
    top: 2px;
  }
  &:hover {
    background: transparent;
    text-decoration: none;
    &:after {
      text-decoration: none;
      color: currentColor;
    }
  }
}

.product-view .product-options p.required {
  margin-top: 0 !important;
  position: absolute !important;
}

.product-collateral {
  padding: 5px;
  max-width: calc(70% - 2em);
  @media screen and (max-width: 1500px) {
    max-width: calc(65% - 2em);
  }
  @media screen and (max-width: 1300px) {
    max-width: calc(60% - 2em);
  }
  @media screen and (max-width: 1100px) {
    max-width: 100%;
  }
}

body.quickview-index-view,
body.catalog-product-view {
  .product-view {
    .product-img-box {
      width: 70%;
      overflow: hidden;
      padding-right: 2em;
      @media screen and (max-width: 1500px) {
        width: 65%;
      }
      @media screen and (max-width: 1300px) {
        width: 60%;
      }
      @media screen and (max-width: 1100px) {
        width: 50%;
      }
      @media screen and (max-width: 770px) {
        width: 100%;
        padding-right: 0;
      }
      .product-image {
        border: 0;
        max-width: 100%;
        overflow: hidden;
      }
    }
    .product-shop {
      width: 30%;
      @media screen and (max-width: 1500px) {
        width: 35%;
      }
      @media screen and (max-width: 1300px) {
        width: 40%;
      }
      @media screen and (max-width: 1100px) {
        width: 50%;
      }
      @media screen and (max-width: 770px) {
        width: 100%;
      }
    }
    .product-image-gallery-owl {
      margin-bottom: 2em;
      .owl-nav {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 100%;
        padding: 0 2em;
        & > * {
          font-size: 2em;
          &.owl-next {
            float: right;
          }
        }
      }
    }
    h1 {
      font-size: 2.25rem;
      font-weight: 600;
    }
    .product-shop {
      .price-info {
        border-top: 1px solid #D0D0D0;
        border-bottom: 1px solid #D0D0D0;
        float: none;
        max-width: none;
        text-align: left;
        padding: 1em 0;
        margin-top: 2em;
        .price-box {
          margin-bottom: 0;
          .old-price, .special-price {
            color: currentColor;
            .price {
              color: currentColor;
            }
          }
          .old-price {
            .price {
              font-size: 1.125rem;
            }
          }
          .special-price {
            .price {
              font-weight: 700;
            }
          }
        }
        .price {
          color: currentColor;
          font-size: 2.25rem;
          font-weight: 600;
          font-family: $secondaryFont;
        }
      }
    }
    .product-options-bottom,
    .product-options {
      background: transparent;
      border: 0;
      margin: 2em 0;
      padding: 0;
      @media screen and (max-width: 992px) {
        margin: 1em 0;
      }
      dl, dd {
        padding: 0;
        margin: 0;
      }
      .configurable-swatch-list {
        margin-bottom: 0;
      }
      & > * + * {
        margin-top: 2em;
      }
      label,
      .swatch-attr label {
        font-family: inherit;
        font-size: inherit;
        color: currentColor;
        display: block;
        .select-label {
          display: none;
        }
      }
    }
    .add-to-cart {
      padding: 0;
      margin: 0;
      border: 0;
      & + * {
        margin-left: 6px !important;
      }
    }
    .product-collateral.tabs {
      background: transparent;
      border: 0;
      margin-bottom: 3em;
      .toggle-tabs {
        background: transparent;
        border: 0;
        margin: 0;
        border-bottom: 1px solid #D0D0D0;
        li {
          span {
            text-transform: none;
            font-size: inherit;
          }
          &.current {
            border: 0;
            span {
              background: transparent;
              color: currentColor;
              font-weight: 600;
              border-bottom: 1px solid #000000;
            }
          }
        }
      }
      & > dl > dd {
        border: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }
    .sharing-links {
      margin-left: 1em !important;
      li {
        padding: 0;
        a {
          text-indent: unset;
          background: none;
          width: auto;
          height: auto;
        }
        & + li {
          margin-left: 1em;
        }
      }
    }
    .configurable-swatch-list {
      li {
        .swatch-link, .swatch-label {
          border-radius: 0;
          background: transparent;
          color: currentColor;
          font-size: inherit;
        }
        .swatch-link {
          border: 1px solid #D0D0D0;
          width: 54px !important;
          height: 54px !important;
          span {
            width: 54px !important;
            height: 54px !important;
            line-height: 54px !important;
          }
        }
        .swatch-label {
          border: 0;
          -webkit-box-shadow: 0 0 0 0 transparent;
          -moz-box-shadow: 0 0 0 0 transparent;
          box-shadow: 0 0 0 0 transparent;
        }
        &:hover {
          .swatch-link {
            border-color: $primary;
          }
        }
        &.selected {
          .swatch-link {
            background: $primary;
            color: #FFFFFF;
            border-color: $primary;
          }
        }
      }
    }
  }
}

.qty-incrementer {
  border: 1px solid #D0D0D0;
  display: inline-block;
  a {
    display: inline-block;
    padding: 0.6em 0.6em;
    font-size: 1.5rem;
    line-height: 1;
    &:hover {
      text-decoration: none;
    }
  }
  input {
    border: 0;
    font-family: $secondaryFont;
    background: transparent;
    color: currentColor;
    &:focus, &:active {
      outline: none;
      background: transparent;
      border-color: transparent;
    }
  }
}

.product-options-bottom {
  .btn-lg {
    @media screen and (max-width: 992px) {
      padding: 15px 35px;
    }
  }
}

#success-message-container {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

h2.product-name, h3.product-name, h4.product-name, h5.product-name, p.product-name {
  font-family: inherit;
  color: currentColor;
  font-size: inherit;
}

.product-collateral .toggle-tabs li.current span, .product-collateral .toggle-tabs li:hover span {
  color: $secondary;
}

.autorelated-block {
  position: relative;
  .item {
    text-align: center;
    .image {
      border: 0;
      margin: 0 0 2em 0;
      display: block;
      img {
        margin: 0 auto;
      }
    }
    .product-name {
      font-family: inherit;
      font-size: 1.125rem;
      font-weight: 700;
    }
    .product-info {
      padding: 0;
      margin-bottom: 2em;
    }
    .price-box {
      font-family: inherit;
      color: currentColor;
      font-size: inherit;
      .price {
        color: currentColor;
      }
      .old-price, .special-price {
        color: currentColor;
        .price {
          color: currentColor;
        }
      }
      .old-price {
        .price {
          font-size: 1rem;
        }
      }
      .special-price {
        .price {
          font-size: 1.125em;
          font-weight: 700;
        }
      }
    }
  }
}

.data-table tbody td, .data-table tfoot td {
  font-family: inherit;
}

#inline_size {
  min-width: 800px;
  padding: 1em;
  .nav.nav-tabs {
    border: 0;
    li.nav-item {
      display: block;
      .nav-link {
        display: inline-block !important;
        background: transparent;
        border: 0;
        border-bottom: 1px solid transparent;
        &.active {
          background: transparent;
          border-color: $primary;
        }
      }
    }
  }
}

.size-table-open {
  font-weight: normal;
  font-size: 0.875em;
}

.chocolat-wrapper {
  .chocolat-bottom {
    display: none;
  }
}

.product-view .product-shop, .product-img-box, .product-collateral, .product-view .block-related, .box-collateral {
  position: relative;
  overflow: hidden;
}

body.quickview-index-view .product-view {
  .product-options-bottom {
    margin-bottom: 0;
  }
  .product-collateral.tabs {
    display: none;
  }
  .product-img-box {
    padding-top: 2em;
    width: 50%;
  }
  .product-shop {
    width: 50%;
  }
}

div.block-layered-nav, div.amshopby-page-container, div.amshopby-filters-top {
  .page-title {
    text-align: center;
  }
}

.designer-quickview-container {
  .products-grid, .products-list {
    .product {
      .product-image-area {
        margin-bottom: 10px;
      }
      .product-info {
        h5 {
          margin-bottom: 0;
        }
      }
    }
  }
}

.configurable-swatch-list li {
  & + li {
    margin-left: 1em;
  }
}

h2.product-name a, h3.product-name a, h4.product-name a, h5.product-name a, p.product-name a {
  color: currentColor;
  &:hover {
    color: $secondary;
  }
}

#customer-reviews .fieldset {
  width: auto;
  h4 {
    font-family: inherit;
    font-size: inherit;
  }
  .form-list {
    font-family: inherit;
    font-size: inherit;
    width: auto;
    color: currentColor;
    label {
      font-family: inherit;
      font-size: inherit;
    }
  }
}

.ratings .rating-box {
  margin: 0 auto;
  .rating {
    mask-image: url(../images/icon_sprite.png);
    -webkit-mask-image: url(../images/icon_sprite.png);
    background-color: #000000;
    mask-position: 0 -600px;
    -webkit-mask-position: 0 -600px;
    background-image: none;
  }
}

.review-summary-table {
  tbody {
    th {
      background-color: transparent;
      font-size: inherit;
      color: currentColor;
      font-family: inherit;
    }
  }
}

.quickview-featured-products {
  sup {
    opacity: 1 !important;
  }
}
.btn {
  white-space: nowrap;
}

.btn-wishlist {
  li {
    a {
      white-space: nowrap;
      &:after {
        content: " ";
        background: url(../images/like.svg) center center no-repeat;
        background-size: 100%;
        display: inline-block;
        width: 20px;
        height: 17px;
        margin-left: 6px;
        position: relative;
        top: 2px;
      }
      &:hover, &:focus {
        &:after {
          background-image: url(../images/like-w.svg);
        }
      }
    }
  }
}


.product-img-box .product-image img {
  max-height: unset;
}
.product-collateral > dl > dt {
  border: 0 !important;
  background: transparent !important;
  color: #000;
  &:hover, &:focus {
    color: #000;
  }
  &:after {
    top: 46%;
    border-left-color: #000;
  }
}
.product-collateral .accordion-open > dl > dt.current:after {
  border-left-color: transparent;
  border-top-color: #000;
}
.availability.available-soon, .availability.out-of-stock {
  margin-top: 20px;
  span {
    color: #df280a;
  }
}
.products-grid .availability {
  position: absolute;
  width: 100%;
  top: -40px;
}
.quickview-featured-products .products-grid .availability {
  bottom: -7px;
  font-size: 10px;
  left: 0;
  text-align: center;
  margin: 0;
  top: unset;
}
#product-attribute-specs-table {
  border: 0;
  * {
    border: 0;
  }
  th {
    color: $primary;
    border-right:0;
    border-bottom:0;
    background: transparent;
  }
}
.tab-content {
  padding: 5px;
}
.not-saleable {
  .add-to-cart-wrapper {
    width: 100%;
    margin-top: 30px;
    padding-left: 0;
    .btn-wishlist {
      margin-bottom: 20px !important;
    }
  }
}
.brand-desc {
  text-align: right;
  p {
    margin: 0;
    text-align: center;
  }
}
.read-more-brand {
  cursor: pointer;
  &:after {
    content: "+";
    display: inline-block;
    font-size: 3rem;
    line-height: 1;
    position: relative;
    top: 10px;
    margin-left: 10px;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    font-weight: 300;
  }
  &:hover, &:focus {
    color: $secondary;
    text-decoration: none;
    &:after {
      color: $secondary;
      -webkit-transition: all 0.4s;
      -moz-transition: all 0.4s;
      -ms-transition: all 0.4s;
      -o-transition: all 0.4s;
      transition: all 0.4s;
    }
  }
}

.filters {
  width: 74%;
  display: inline-block;
  margin: 0;
}
.select-country-link {
  float: right;
  line-height: 56px;
  position: relative;
  cursor: pointer;
  padding-left: 40px;
  &:before {
    content: " ";
    background: url(../images/pin.svg) center center no-repeat;
    background-size: 80%;
    width: 30px;
    height: 56px;
    display: inline-block;
    position: absolute;
    left: 0;
  }
}
.popup-country {
  line-height: 18px;
  width: 100%;
  max-width: 580px;
  padding: 15px;
  .block-customer-address {
    padding: 1.5rem;
    margin-bottom: 0;
    min-height: 400px;
    li + li {
      margin-top: 20px;
    }
  }
  .select-country-link {
    float: none;
    margin: 0 auto;
    text-align: center;
    padding-left: 0;
    &:before {
      display: none;
    }
    & > span {
      font-weight: 600;
      font-size: 2rem;
      position: relative;
      padding-left: 40px;
      &:before {
        content: " ";
        background: url(../images/pin.svg) center center no-repeat;
        background-size: 80%;
        width: 30px;
        height: 56px;
        display: inline-block;
        position: absolute;
        left: 0;
      }
    }
  }
}

.popup-summer {
  width: 100% !important;
  max-width: 600px;
  height: auto !important;
  .summer-title {
    color: $white;
    margin-bottom: 2rem;
    &:before {
      content: " ";
      background: url(../images/sun.svg) center center no-repeat;
      background-size: 100%;
      width: 50px;
      height: 50px;
      margin: 0 auto;
      display: block;
      margin-bottom: 2rem;
    }
  }
}

.single-choice.amsopby-flag-clickfirst {
  padding-left: 0;
  .amshopby-slider .handle {
    background: #000 !important;
  }
}