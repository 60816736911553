.recent-posts {
  .postWrapper {
    position: relative;
    overflow: hidden;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
    .postTitle {
      border: 0;
      h3 {
        font-size: inherit;
        color: currentColor;
        a {
          color: currentColor;
        }
      }
    }
  }
  &.last-one {
    .postWrapper {
      .content {
        position: relative;
        z-index: 1;
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        z-index: 0;
        background: linear-gradient(rgba(0, 0, 0, 0.45), transparent);
      }
    }
  }
}
.postTitle {
  margin-bottom: 0;
}
.postWrapper {
  padding-bottom: 0;
  clear: both;

}
.post.post-blog {
  position: relative;
  @media screen and (max-width: 1200px) {
    max-width: 50%;
    width: 50%;
    flex: 50%;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    flex: 100%;
  }
  .postBanner {
    position: relative;
    img {
      width: 100%;
      max-width: unset;
    }
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      z-index: 0;
      background: linear-gradient(rgba(0, 0, 0, 0.65), transparent);
    }
    .post-details {
      position: absolute;
      color: #FFFFFF;
      top: 2em;
      width: calc(100% - 4em);
      transform: translateX(-50%);
      left: 50%;
      z-index: 1;
    }
  }
}

body.cms-index-index {
  .main.container {
    padding: 0;
    .block .block-title {
      font-weight: 600;
    }
  }
}

.brand.brand-square {
  background-color: #111111;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  min-height: 80vh;
  @media screen and (max-width: 768px) {
    justify-content: flex-end !important;
    min-height: 60vh;
    .font-weight-bold {
      color: #f8f9fa !important;
      z-index: 2;
    }
    .btn {
      border-color: #f8f9fa !important;
      color: #f8f9fa !important;
      z-index: 2;
      &:hover, &:focus {
        color: #000 !important;
      }
    }
    &.p-3 {
      padding: 2rem !important;
    }
    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      display: block;
      width: 100%;
      height: 80%;
      z-index: 1;
      background: linear-gradient(transparent,rgba(0, 0, 0, 0.65));
    }
  }
}

.recent-blog-block-wide {
  margin-bottom: 10px;
  @media screen and (max-width: 1200px) {
    margin-bottom: 0;
  }
}

div.post:nth-child(3) > div:nth-child(1) > div:nth-child(1), div.recent-posts:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
  &:after {
    content: " ";
    width: 100%;
    height: 40px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #F0F0F0;
    @media screen and (max-width: 1200px) {
      display: none !important;
    }
  }
}