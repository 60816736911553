input[type="radio"],
input[type="checkbox"] {
  &:not([name^=ratings]) {
    opacity: 0;
    position: absolute;
    & + label {
      position: relative;
      display: inline-block;
      padding-left: 22px;
    }
    & + label::before,
    & + label::after {
      position: absolute;
      content: "";
      display: inline-block;
    }
    & + label::before {
      background: #FFFFFF;
      height: 16px;
      width: 16px;
      border: 1px solid $primary;
      left: 0px;
      top: 3px;
      cursor: pointer;
    }
    & + label::after {
      height: 8px;
      width: 8px;
      display: block;
      background: $primary;
      left: 4px;
      top: 7px;
    }
  }
}

input[type="radio"] + label::before,
input[type="radio"] + label::after {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

input[type="radio"],
input[type="checkbox"] {
  &:not([name^=ratings]) {
    & + label::after {
      content: none;
    }
  }
}

input[type="radio"]:checked,
input[type="checkbox"]:checked {
  &:not([class|=ratings]) {
    & + label::after {
      content: "";
      color: $primary;
    }
  }
}

.select2-container {
  min-width: fit-content;
  width: 100% !important;
  .select2-selection--single {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    border-color: $input-border-color;
    height: auto;
    &:hover, &:focus {
      outline: none;
    }
    .select2-selection__rendered {
      color: currentColor;
    }
  }
  .select2-results {
    font-size: 0.875rem;
  }
  .select2-dropdown {
    border: 1px solid;
    border-radius: 0;
  }
  .select2-results__option {
    text-transform: uppercase;
    text-align: center;
    font-size: inherit;
  }
  .select2-results__option--highlighted[aria-selected] {
    background-color: #000000;
  }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: currentColor;
}

::-moz-placeholder { /* Firefox 19+ */
  color: currentColor;
}

:-ms-input-placeholder { /* IE 10+ */
  color: currentColor;
}

:-moz-placeholder { /* Firefox 18- */
  color: currentColor;
}

label {
  font-family: inherit;
  font-size: inherit;
}

.form-list .control label, .sp-methods dt label, #checkout-shipping-method-load .sp-methods dd label, #co-shipping-method-form .sp-methods dd label, .product-options ul.options-list label {
  background: transparent;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 22px;
  color: currentColor;
  &:hover {
    background: transparent;
    cursor: pointer;
  }
}

input[type=email], input[type=search], input[type=number], input[type=password], input[type=tel], input[type=text] {
  @extend .form-control;
  &:focus {
    border-color: $secondary;
  }
}

form .legend {
  color: currentColor;
  font-family: $secondaryFont;
}

.input-text {
  @extend .form-control;
}

.select2-container .select2-dropdown {
  border: 0;
  background-color: #f6f5f3;
}

.form-control, input[type=email], input[type=search], input[type=number], input[type=password], input[type=tel], input[type=text], .input-text {
  background-color: #f0f0f0;
}