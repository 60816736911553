.skip-cart .count, .skip-link.skip-active .count {
  color: currentColor;
}

.header-minicart {
  .block-subtitle {
    font-family: inherit;
    color: currentColor;
    .close {
      color: currentColor;
      top: 0;
      right: 0;
    }
  }
  .mini-products-list {
    padding: 0;
    .btn-remove {
      z-index: 1;
      &:after {
        font-size: 26px;
        top: 0;
      }
    }
    li {
      margin: 0;
      padding: 10px 0 5px 0;
    }
    .product-image {
      float: none;
      display: inline-block;
    }
    .product-details {
      float: none;
      margin: 0;
      padding: 0;
      .product-name {
        a {
          color: currentColor;
        }
      }
    }
  }
  .minicart-actions {
    padding: 0;
  }
  .subtotal {
    background: transparent;
    color: currentColor;
    line-height: 1;
    text-align: right;
    border-top: 1px solid #EEEEEE;
    padding-top: 1em;
    .label {
      text-transform: none;
      color: currentColor;
    }
    .price {
      color: currentColor;
    }
  }
}

.cart .cart-totals table tfoot strong span, .cart .cart-totals table tfoot span.price {
  font-family: inherit;
}

.price-box .price, .price {
  font-family: inherit;
}

.cart .cart-totals table {
  font-family: inherit;
  font-size: inherit;
  text-transform: none;
  color: currentColor;
}

.cart-table {
  font-family: inherit;
  float: none;
  width: 100%;
  color: currentColor;
  thead th, tbody td {
    font-family: inherit;
  }
  .product-cart-sku {
    font-size: inherit;
    font-family: inherit;
  }
  .cart-links {
    text-align: center;
    & > li {
      font-size: inherit;
      & > a {
        font-size: inherit;
        display: inline-block;
        i {
          margin-right: 5px;
        }
      }
    }
  }
  .product-cart-image {
    min-width: 160px;
    .product-image img {
      width: auto;
    }
  }
  .product-cart-actions {
    .qty {
      margin: 0 auto;
      border-color: transparent;
    }
  }
}

.cart .cart-totals table tfoot td {
  font-size: inherit;
}

.cart-forms, .cart-totals-wrapper, .crosssell {
  float: none;
  clear: both;
  width: auto;
  min-width: 500px;
}

#discount-coupon-form label, .cart .giftcard label {
  font-family: inherit;
  font-size: inherit;
  text-transform: none;
  max-width: none;
  margin: 0;
}

#discount-coupon-form, .cart .giftcard {
  width: auto;
  min-width: 362px;
}

.checkout-types {
  float: none;
  width: 100%;
}

#discount-coupon-form {
  h2 {
    display: block;
    font-size: inherit;
  }
  .discount-form {
    margin-top: 2em;
    label {
      display: block;
    }
    .field-wrapper {
      display: block;
      .input-text {
        width: 100%;
        margin: 10px 0;
        height: auto;
      }
    }
  }
}
.cart .cart-totals table tfoot strong {
  font-weight: 700;
}
.cart-table thead {
  font-size: 1.3em;
}
#discount-coupon-form .validation-advice, .cart .giftcard .validation-advice {
  display: block;
  margin-bottom: 10px;
}

.cart-totals, .cart-forms .discount, .cart-forms .giftcard, .cart-forms .shipping {
  padding: 1em;
}

.item-options {
  font-family: inherit;
  font-size: inherit;
}

.product-image, .no-touch .product-img-box .product-image:not(.zoom-available):hover {
  display: inline-block;
  border: 0;
}

.data-table tbody td .item-options {
  margin: 0;
  padding: 0;
}

.data-table th {
  text-transform: none;
  font-weight: 600;
}

.cart-totals, .cart-forms .discount, .cart-forms .giftcard, .cart-forms .shipping,
.cart-table tr,
.data-table thead th, .data-table tbody td {
  border-color: #D0D0D0;
}

.cart-table thead th, .cart-table tbody td {
  vertical-align: middle
}

.opc #opc-login p:not(.required) {
  font-family: inherit;
  font-size: inherit;
  color: currentColor;
  font-style: inherit;
}

.opc .section {
  .step-title {
    border-color: $primary;
    .number {
      float: left;
      display: block;
    }
  }
}

.opc .section.allow:not(.active) .step-title a {
  margin-left: auto;
}

.opc .section.allow .step-title:hover h2, .opc .section.active .step-title h2 {
  color: currentColor;
  position: relative;
}

.opc .section.allow .step-title {
  clear: both;
  height: auto;
  overflow: hidden;
}

.opc .section .step-title {
  display: flex;
  align-items: center;
}

.opc .section .step-title h2 {
  height: auto;
  position: relative;
  margin-left: 20px;
}

.opc .section label {
  font-size: inherit;
}

.opc #opc-login .step,
.step.a-item {
  padding: 2em 0;
}

.opc .section.active .step-title {
  border-color: $primary;
}

.opc .section .step-title .number, .opc .section.allow.active .step-title .number, .no-touch .opc .section.allow:hover .step-title .number {
  width: 40px;
  left: 0;
  height: 40px;
  background-color: $primary;
  position: relative;
  top: 0;
  margin: 10px 0;
  line-height: 40px;
  font-weight: 700;
}

.opc .section.allow .step-title .number {
  background: #CCCCCC;
}

.block-progress {
  border-color: transparent;
  dt, dd {
    color: currentColor;
    font-family: inherit;
    text-transform: none;
    font-style: unset;
    padding-left: 0;
  }
  dd {
    address {
      font-style: unset;
    }
  }
}

.opc .section .step {
  padding-left: 0;
  padding-right: 0;
}

#wishlist-table {
  .wishlist-sku {
    font-size: inherit;
    font-family: inherit;
  }
}
.opc .section .step-title h2 {
  font-weight: 500;
}
.display-single-price .cart-forms {
  width: 100% !important;
}
.discount-form .btn-outline-primary {
  background-color: transparent;
}
.btn-outline-danger {
  color: #848484;
  border-color: transparent;
  &:hover, &:focus {
    color: #000;
    background-color: transparent;
    border-color: transparent;
  }
}
.cart-totals .checkout-types .btn-checkout {
  margin-bottom: 0;
  font-size: 1.25rem;
  text-transform: capitalize !important;
  padding: 20px;
}

#onepage-guest-register-button {
  padding: 10px 50px;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
  margin-top: 20px;
}
#checkout-step-login > div > div:nth-child(2) > div > button {
  padding: 10px 50px;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}
.checkout-onepage-index {
  .btn-primary {
    padding: 10px 50px;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0;
    margin-bottom: 30px;
  }
}
.sp-methods dt label {
  min-width: unset;
}
.select2-container .select2-dropdown {
  background-color: #fff;
}
.select2-container--default .select2-selection--single {
  background-color: transparent;
  height: calc(1.5em + 1rem + 2px);
  .select2-selection__arrow {
    height: calc(1.5em + 1rem + 2px);
  }
  .select2-selection__rendered {
    line-height: 38px;
  }
}
.product-cart-remove .btn-primary {
  background-color: #848484;
  border-color: #848484;
}
@media screen and (max-width: 992px) {
  .display-single-price .cart-forms {
    flex-direction: column;
    padding-right: 0;
  }
  .display-single-price .cart-totals-wrapper, .display-single-price .crosssell {
    padding-left: 0;
  }
  .display-single-price .cart-forms, .display-single-price .cart-totals-wrapper, .display-single-price .crosssell {
    width: 100%;
  }
}

.block-close-shop-cart {
  padding-right: 0px;
  background: #000 !important;
  color: white;
  .discount {
    background: #000 !important;
  }
  h2 {
    color: white;
  }
}