$primaryFontColor: #000000;
$primaryColor: #000000;

$primaryFont: 'Lato', sans-serif;
$secondaryFont: 'Montserrat', serif;

$link-color: $primaryColor;

$primary: #000;
$secondary: #898989;

$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;
$input-border-color: $primary;
$input-padding-x: 1rem;
$input-padding-y: 0.5rem;
$input-padding-x-sm: 1rem;
$input-padding-y-sm: 0.5rem;
$input-padding-x-lg: 1rem;
$input-padding-y-lg: 0.5rem;
$input-box-shadow: 0;
$input-focus-box-shadow: 0;

$btn-box-shadow: 0 0;
$btn-focus-box-shadow: 0 0;
$btn-focus-width: 0;
$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;
$btn-font-weight: 600;
$btn-padding-y: 8px;
$btn-padding-y-lg: 20px;
$btn-padding-x-lg: 50px;

$breadcrumb-bg: #EFEFEF;
$breadcrumb-padding-x: 0;
$breadcrumb-padding-y: 5px;
$breadcrumb-margin-bottom: 0;
$breadcrumb-divider: "_";
$breadcrumb-item-padding: 0;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * 1),
  2: ($spacer * 2),
  3: ($spacer * 3),
  4: ($spacer * 4),
  5: ($spacer * 5),
  6: ($spacer * 6),
  7: ($spacer * 7),
  8: ($spacer * 8),
  9: ($spacer * 9),
  10: ($spacer * 10),
);